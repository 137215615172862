<template>
  <b-row>
    <!-- use component -->
    <package-form-list-modal ref="PackageFormListModal" />
    <!-- end use component -->

    <b-col sm="12">
      <data-table-local
        :columns="_columns_package_form_list"
        :items="_get_package_list_datas"
        @clickNew="$refs.PackageFormListModal.show()"
        :viewAble="false"
        @clickEdit="clickEdit"
      />
      <!-- @clickView="clickView"
      @clickDelete="clickDelete" -->
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  components: {
    PackageFormListModal: () =>
      import("./PackageFormList/PackageFormListModal.vue"),
  },
  computed: {
    ...mapGetters("PackageForm", [
      "_columns_package_form_list",
      "_get_package_list_datas",
    ]),
  },
  methods: {
    clickEdit(data) {
      const _data = this.cp(data)

      this.$refs.PackageFormListModal.show(_data)
    },
  },
}
</script>
